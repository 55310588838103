var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AdminTemplate',[_c('el-col',{attrs:{"span":18,"offset":3}},[_c('div',{staticClass:"userAnswer bg-primary pd-5 text-white text-center"},[_c('p',{staticClass:"mg-y-0 font-36"},[_vm._v(" "+_vm._s(_vm.userAnswer.total)+"/"+_vm._s(_vm.room.players.length)+" ")]),_c('p',{staticClass:"mg-y-0 font-32"},[_vm._v("ส่งคำตอบ")])]),_c('div',{staticClass:"font-34 text-danger text-center mg-t-3",class:_vm.countTime < 6
          ? 'animate__fadeIn animate__animated animate__infinite'
          : null},[_c('i',{staticClass:"fas fa-clock"}),_vm._v(" เหลือเวลา "+_vm._s(_vm.countTime)+" วินาที ")]),_c('h1',{staticClass:"text-center mg-y-1"},[_vm._v(_vm._s(_vm.exam.question))]),_vm._l((_vm.exam.choice),function(data,index){return _c('div',{key:index,staticClass:"border-choice position-relative",class:index == 0
          ? 'border-primary'
          : index == 1
          ? 'border-danger'
          : 'border-warning'},[_c('div',{staticClass:"text-white font-44 border-circle",class:index == 0 ? 'bg-primary' : index == 1 ? 'bg-danger' : 'bg-warning'},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('span',{staticClass:"font-24"},[_vm._v(_vm._s(data))])])}),_c('el-col',{staticClass:"mg-b-5 mg-t-6",attrs:{"span":12,"offset":6}},[_c('el-button',{staticClass:"w-100",attrs:{"type":"primary"},on:{"click":function($event){return _vm.nextStep()}}},[_vm._v("ดูผลคะแนน")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }